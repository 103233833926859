// Since CM & PROD will use the same bundle when deployed,
// environment vars are merged into one single object,
// (if window.envConfig is not present/replaced when deploying to prod env vars are defaulted).

let envConf = {
  siteUrl: process.env.GATSBY_SITE_URL,
  mcwsUrl: process.env.GATSBY_MCWS_URL,
  ehmpUrl: process.env.GATSBY_EHMP_URL,
  ehmUrl: process.env.GATSBY_EHM_URL,
  subdomain: process.env.GATSBY_SUBDOMAIN,
  launchScriptUrl: process.env.GATSBY_LAUNCH_SCRIPT_URL,
  hotJarId: process.env.GATSBY_HOT_JAR_ID,
};

if (typeof window !== 'undefined' && window.envConfig) {
  envConf = { ...envConf, ...window.envConfig };
}

export default envConf;
